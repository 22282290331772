import React, { Component } from 'react';
import canUseDOM from 'can-use-dom';
import { screens } from '@/components/utils/mediaQuery';
import viewport from '@/components/utils/viewport';
import Swiper from 'swiper';
import { Link } from 'gatsby';
import cx from 'classnames';
import 'swiper/css/swiper.min.css';
import styles from './styles.module.scss';
import { ButtonA, ButtonLink} from '@/components/UI/Button';

const GRID_ITEMS = [
  {
    right: '5%',
    bottom: '5%',
  },
  {
    top: '2%',
    right: '20%',
  },
  {
    top: '25%',
    left: '30%',
  },
  {
    top: '60%',
    left: '25%',
  },
  {
    top: '10%',
    left: '5%',
  },
];

const social = [
  {
    title: 'email',
    href: 'mailto:otani.travelphotography@gmail.com'
  },
  {
    title: 'facebook',
    href: 'https://www.facebook.com/KOJI-PHOTo-101430347982975/'
  },
  {
    title: 'instagram',
    href: 'https://www.instagram.com/otani_k'
  }
];

const Slide = ({ path, title, index, slideIndex, gallery, isMobile }) => {
  const [hover, setHover] = React.useState(false);
  const handleMouseEnter = function() {
    setHover(true);
    if (canUseDOM) {
      document.body.classList.add('has-hovered-link__hidden-bg');
    }
  };
  const handleMouseLeave = function() {
    setHover(false);
    setActiveImgIndex(0)
    if (canUseDOM) {
      document.body.classList.remove('has-hovered-link__hidden-bg');
    }
  };

  const imgRef = React.useRef();
  const [activeImgIndex, setActiveImgIndex] = React.useState(0);
  const handleMouseMove = function(e) {
    // console.log(e.clientX);
    const img = imgRef.current.getBoundingClientRect()
    const perc = Math.abs(Math.ceil((e.clientX - img.x) / img.width * gallery.length));
    setActiveImgIndex(perc)
  }

  return (
    <Link
      to={path}
      className={cx(styles.picWrapper, hover && styles.picWrapperHover )}
      data-swiper-parallax-x={!isMobile ? ((index === 0 || index === 1 || index === 3) ? '-100' : '100') : '0'}
      data-swiper-parallax-y={!isMobile ? ((index === 0 || index === 1 || index === 3) ? '-100' : '100') : '0'}
      onMouseEnter={!isMobile ? handleMouseEnter : null}
      onMouseLeave={!isMobile ? handleMouseLeave : null}
      onMouseMove={!isMobile ? handleMouseMove : null}
      style={!isMobile ? GRID_ITEMS[index] : {}}
    >
      <img
        ref={imgRef}
        className={cx(styles.cover, slideIndex !== 0 &&  'swiper-lazy')}
        src={gallery[activeImgIndex] ? gallery[activeImgIndex] : gallery[0]}
        alt=""
      />
      <div className={cx(styles.itemInner, hover && styles.itemInnerHover)}>
        <div
          className={cx(styles.title, hover && styles.titleHover)}
        >{title}</div>
      </div>
      <div
        className={styles.progress}
        style={{
          width: `${1 / gallery.length * 100 * activeImgIndex}%`
          // transform: `translateX(${1 / gallery.length * 100 * activeImgIndex})`
        }}
      />
      {slideIndex !== 0 && <div className="swiper-lazy-preloader"><span><span className="progress2" /></span></div>}
    </Link>
  );
};

const OPTIONS =  {
  loop: false,
  parallax: true,
  direction: 'vertical',
  mousewheel: true,
  freeMode: true,
  lazy: {
    loadPrevNext: false,
    loadPrevNextAmount: 0
  },
};

if (viewport.width < screens.tablet) {
  OPTIONS.lazy.loadPrevNext = true;
  OPTIONS.lazy.loadPrevNextAmount = 2;
}

class HomeSlider extends Component {
  componentDidMount() {
    this.swiper = new Swiper(this.$root, OPTIONS);
  }

  render() {
    const { items } = this.props;
    const groupedItems = [[items[0]]];
    const itemsPerSlide = 4;
    const slideCount = Math.ceil(items.length / itemsPerSlide); 
    for (let i = 0; i < slideCount; i++ ) {
      const localItems =  
      [
        items[i] && items[i],
        items[i] && items[i+1],
        items[i] && items[i+2],
        items[i] && items[i+3],
        items[i] && items[i+1]
      ]
      groupedItems.push([...localItems]);
    }

    return (
      <React.Fragment>
        <div
          style={{
            display: 'none'
          }}
        >
          {items && items.map((v, i) => 
            <React.Fragment key={i}>
              {v.gallery && v.gallery.map(e =>
                <img src={e} key={e} />
              )}
            </React.Fragment>
          )}
        </div>
        <div
          className={styles.root}
          ref={ref => (this.$root = ref)}
        >
          <div className={cx(styles.wrapper, 'swiper-wrapper')}>
            {items && items.map((slide, ind) => (
              <div className={cx(styles.slide, 'swiper-slide')} key={ind}>
                {ind === 0 ? (
                  <React.Fragment>
                    <div className={cx(styles.container, 'container')}>
                      <div className={styles.intro}>
                        <div className={styles.text}>
                          OTANI KOJI
                        </div>
                        <div className={styles.social}>
                        {social && social.map((el, i) => (
                          <ButtonA hasDot color={'black'} size={'small'} key={i} href={el.href} target="_blank" className={styles.socialLink}>{el.title}</ButtonA>
                        ))}
                        </div>
                      </div>
                    </div>
                    <Slide
                      key={ind}
                      index={ind}
                      path={`/gallery/${slide.region.id}/${slide.country.id}`}
                      imgSrc={slide.gallery[0]}
                      title={slide.country.name}
                      slideIndex={0}
                      gallery={slide.gallery}
                    />
                  </React.Fragment>
                ) : (
                    <React.Fragment>
                      <Slide
                        key={ind}
                        index={ind}
                        path={`/gallery/${slide.region.id}/${slide.country.id}`}
                        imgSrc={slide.path}
                        title={slide.country.name}
                        gallery={slide.gallery}
                      />
                    </React.Fragment>
                )}
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HomeSlider;
