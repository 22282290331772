// https://github.com/component/ease/blob/master/index.js

export function outExpo(pos) {
  return pos === 1 ? 1 : -Math.pow(2, -10 * pos) + 1;
}

export function outCubic(pos) {
  return Math.pow(pos - 1, 3) + 1;
}

export function inSine(n) {
  return 1 - Math.cos((n * Math.PI) / 2);
}

export function outSine(n) {
  return Math.sin((n * Math.PI) / 2);
}

export function inOutSine(n) {
  return 0.5 * (1 - Math.cos(Math.PI * n));
}

export function inQuint(n) {
  return n * n * n * n * n;
}

export function outQuint(n) {
  return --n * n * n * n * n + 1;
}

export function inOutQuint(n) {
  n *= 2;
  if (n < 1) return 0.5 * n * n * n * n * n;
  return 0.5 * ((n -= 2) * n * n * n * n + 2);
}
