import React from "react";
import { graphql } from 'gatsby';
import SEO from "@/components/seo";
import NoSSR from '@/components/no-ssr';
import Layout from "@/components/layout";
import HomeSider from '@/components/HomeSider';

const PAGE = {
  pageTitle: 'KOJI OTANI | Main',
  sectionTitle: '...',
  pageDescription: '...',
  headerColor: 'white',
}

const HomePage = ({ data }) => {
  const homeGallery = data && data.slider.edges.map(e => e.node);
  const reverseItems = data && data.slider.edges.map(e => e.node).reverse();

  return (
    <Layout page={PAGE}>
      <SEO title={PAGE.pageTitle} />
      <NoSSR>
        {homeGallery && <HomeSider items={reverseItems} />}
      </NoSSR>
    </Layout>
  );
};

export default HomePage;

export const query = graphql`
  query {
    slider: allHomeGalleryJson {
      edges {
        node {
          region {
            name
            id
          }
          country {
            name
            id
          }
          gallery
        }
      }
    }
  }
`;