export function getService(win, id, factory) {
  let services = win._services;

  if (!services) {
    services = win._services = {};
  }

  let service = services[id];

  if (!service) {
    return (services[id] = factory(win));
  }

  return service;
}
