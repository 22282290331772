import React from 'react';
import MediaQuery from 'react-responsive';

export const screens = {
  tablet: 768,
  desktop: 1024,
  desktop1280: 1280,
  desktop1366: 1366,
  desktop1440: 1440,
};

export const Desktop1440 = props => (
  <MediaQuery {...props} query={`(min-width: ${screens.desktop1440}px)`} />
);
export const Desktop1366 = props => (
  <MediaQuery {...props} query={`(min-width: ${screens.desktop1366}px)`} />
);
export const Desktop1280 = props => (
  <MediaQuery {...props} query={`(min-width: ${screens.desktop1280}px)`} />
);
export const Desktop = props => (
  <MediaQuery {...props} query={`(min-width: ${screens.desktop}px)`} />
);
export const Tablet = props => <MediaQuery {...props} query={`(min-width: ${screens.tablet}px)`} />;

export const Mobile = props => <MediaQuery {...props} query={`(max-width: ${screens.tablet}px)`} />;
