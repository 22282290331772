import { getService } from './namespace.js';
import { subscribe } from 'subscribe-ui-event';
import raf from 'raf';
import * as easingFormulas from './easing';
import canUseDOM from './canUseDom.js';
import { screens } from './mediaQuery.js';

const noop = () => {};

if (canUseDOM) {
  window.__viewport = {
    blocked: false,
  };
}

class Viewport {
  constructor() {
    this._handleScroll = this._handleScroll.bind(this);
    this._handleResize = this._handleResize.bind(this);

    subscribe('scroll', this._handleScroll, {
      useRAF: true,
      enableScrollInfo: true,
    });

    subscribe('touchmove', this._handleScroll, {
      useRAF: true,
      enableScrollInfo: true,
    });

    subscribe('resize', this._handleResize, {
      useRAF: true,
      enableResizeInfo: true,
    });

    this._handleScroll(null, {
      scroll: {
        top: window.pageYOffset,
      },
    });

    this._handleResize(null, {
      resize: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  }

  // this is internal stuff. do not reference directly
  state_ = {
    pageY: canUseDOM ? window.pageYOffset : 0,
    width: canUseDOM ? window.innerWidth : 0,
    height: canUseDOM ? window.innerHeight : 0,
    document: canUseDOM ? document.documentElement.getBoundingClientRect() : {},
  };

  get pageY() {
    return this.state_.pageY;
  }

  get pageYBottom() {
    return this.state_.pageY + this.state_.height;
  }

  get height() {
    return this.state_.height;
  }

  get width() {
    return this.state_.width;
  }

  get document() {
    return this.state_.document;
  }

  get density() {
    if (canUseDOM) {
      return window.devicePixelRatio || 1;
    }

    return 1;
  }

  block() {
    window.__viewport.blocked = true;
  }

  release() {
    window.__viewport.blocked = false;
  }

  _handleScroll(_, { scroll }) {
    this.state_.pageY = scroll.top;
  }

  _handleResize(_, { resize }) {
    this.state_.width = resize.width;
    this.state_.height = resize.height;
    this.state_.document = document.documentElement.getBoundingClientRect();
  }

  subscribe(eventName, handler, options = {}) {
    return subscribe(eventName, handler, {
      useRAF: true,
      ...options,
    });
  }

  scrollTo({
    y = 0,
    speed = 1500,
    duration = 0.8,
    easing = 'outCubic',
    onUpdate = noop,
    onComplete = noop,
    excludeNavBar,
  }) {
    // speed: time in pixels per second
    // easing: easing equation to use

    let cancelled = false;
    let currentTime = 0;
    const scrollY = this.state_.pageY;

    const $navContainer = excludeNavBar ? document.body.querySelector('#nav_container') : null;
    const navHeight = $navContainer
      ? this.width >= screens.desktop1366
        ? 0
        : $navContainer.offsetHeight
      : null;

    const scrollTargetY = $navContainer ? y - navHeight : y;

    // min time .1, max time .8 seconds
    const time =
      duration || Math.max(0.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8));

    // add animation loop
    function tick() {
      if (cancelled) {
        return false;
      }

      currentTime += 1 / 60;

      var p = currentTime / time;
      var t = easingFormulas[easing](p);

      if (p < 1) {
        raf(tick);

        window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t);
      } else {
        window.scrollTo(0, scrollTargetY);
        onComplete();
      }
    }

    tick();

    return () => {
      cancelled = true;
    };
  }
}

function viewportFor(window) {
  return getService(window, 'viewport', () => new Viewport());
}

const viewport = canUseDOM ? viewportFor(window) : {};

export default viewport;
